<template>
  <div class="m-0 p-3">
    <back-button class="ml-1"/>
    <div class="ml-2 d-flex justify-content-between">
      <h1>{{ 'Edit Event' }}</h1>
    </div>
    <event-form ref="form" @update="updateEvent" :event="response.event" form-type="update" :loader="loader"/>
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import Form from "@/views/Components/SuperAdmin/Event/Form";
import generateErrorMessageMixin from '@/mixins/generateErrorMessageMixin.js';

export default {
  name: "EditEvent",
  mixins: [generateErrorMessageMixin],
  components: {
    BackButton,
    EventForm: Form
  },
  data() {
    return {
      loader: false,
      response: {
        event: {...this.$store.getters['EventModule/getEvent']}
      }
    }
  },
  mounted() {
    this.getEvent()
  },
  methods: {
    /**
     * Get Requested Event
     */
    getEvent() {
      let vm = this
      vm.loader = true
      const payload = {
        id: this.$route.params.id,
      }
      vm.$store.dispatch('EventModule/_getRequestedEvent', payload)
        .then(response => {
          vm.response.event = response.data.data
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Event',
            message: message
          })
          vm.$router.back()
        })
        .finally(() => {
          vm.loader = false
        })
    },

    /**
     * Update Event
     * @param formData
     */
    updateEvent(formData) {
      let vm = this
      this.loader = true
      vm.$store.dispatch('EventModule/_updateEvent', {formData})
        .then(response => {
          vm.$notify.success({
            title: 'Success',
            message: 'Event Updated Successfully!'
          })
          vm.$router.back()
        })
        .catch(error => {
          const message = error.response && error.response.data && error.response.data.errors ? vm.generateErrorMessage(error.response.data.errors)
            : (error.response.data.message ?? 'Something went wrong please try again in few minutes.')
          vm.$notify.error({
            title: 'Event',
            dangerouslyUseHTMLString: true,
            message: message
          })
        })
        .finally(() => {
          vm.loader = false
        })
    }
  }
}
</script>

<style scoped>

</style>
