<template>
  <div v-loading="loader || loaders.form">
    <validation-observer v-slot="{ handleSubmit }" ref="formValidator">
      <form @submit.prevent="handleSubmit(onSubmit)" ref="form">
        <div class="mt-4 cardStyle p-3">
          <div class="program-user-form mt-1">
            <div class="row">
              <div class="col-12 col-md-6">
                <base-input
                  name="Event Name"
                  label="Event Name*"
                  v-model="request.event.event_title"
                  :rules="{ custom_required: true }"
                  placeholder="Event Name"/>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Event Type"
                  label="Event Type*"
                  class="input-height-equal"
                  v-model="request.event.type"
                  :options="dropdownsOptions.type"/>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Venue Type"
                  label="Venue Type*"
                  class="input-height-equal"
                  v-model="request.event.venue_type"
                  :options="dropdownsOptions.venue"/>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Weight Loss Type"
                  label="Weight Loss Type*"
                  class="input-height-equal"
                  v-model="request.event.weight_loss_type"
                  :options="dropdownsOptions.weightLoss"/>
              </div>
              <div class="col-12">
                <base-input
                  label="Event Description*"
                  name="Event Description"
                  :rules="{ custom_required: true }">
                  <editor
                    v-model="request.event.event_description"
                    :api-key=editorApiKey
                    :init=editorOption
                  />
                </base-input>
              </div>
              <div class="col-12 col-md-6">
                <div class="row">
                  <div class="col-6 pr-0">
                    <base-input
                      label="Start Time* (Eastern Standard Time)"
                      name="Start Time"
                      inputGroupClasses="custom-time-picker"
                      :rules="{ custom_required: true, validate_start_time: [request.event.time, request.event.end_time] }">
                      <base-time-picker
                          v-model="request.event.time"
                      />
                    </base-input>
                  </div>
                  <div class="col-6 pl-0">
                    <base-input
                      label="End Time* (Eastern Standard Time)"
                      name="End Time"
                      :rules="{ custom_required: true, validate_end_time: [request.event.time, request.event.end_time] }">
                      <base-time-picker
                          v-model="request.event.end_time"
                      />
                    </base-input>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <base-input label="Date*" name="Date" :rules="{ custom_required: true }">
                  <div>
                    <b-input-group class="">
                      <el-input
                        style="height: 40px; width: calc(100% - 60px); border-top-right-radius: 0px;border-bottom-right-radius: 0px"
                        v-mask="'##/##/####'"
                        id="example-input"
                        v-model="request.event.date"
                        type="text"
                        placeholder="MM/DD/YYYY"
                        autocomplete="off"
                        @blur="handleDate"
                      ></el-input>
                      <b-input-group-append>
                        <b-form-datepicker
                          :min="today"
                          button-only
                          right
                          locale="en-US"
                          aria-controls="example-input"
                          :date-format-options="{ month: '2-digit' ,day: '2-digit', year: 'numeric' }"
                          @context="onContextDate"
                        ></b-form-datepicker>
                      </b-input-group-append>
                    </b-input-group>
                  </div>
                </base-input>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Location"
                  label="Location*"
                  class="input-height-equal"
                  v-model="request.event.location_id"
                  :options="dropdownsOptions.locations"
                  @change="updateEventAddress"
                />
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  :disabled="request.event.location_id != ''"
                  name="Location Address"
                  label="Location Address*"
                  v-model="request.event.location"
                  :rules="{ custom_required: true }"
                  placeholder="Location Address"/>
              </div>
              <div class="col-12 col-md-6">
                <base-select
                  name="Status"
                  label="Status*"
                  class="input-height-equal"
                  v-model="request.event.status"
                  :options="dropdownsOptions.status"/>
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  name="Email"
                  label="Email"
                  type="email"
                  v-model="request.event.email"
                  placeholder="Email"/>
              </div>
              <div class="col-12 col-md-6">
                <base-input
                  name="hosted_by"
                  label="Hosted By"
                  type="text"
                  v-model="request.event.hosted_by"
                  placeholder="Hosted By"/>
              </div>
              <div class="col-6">
                <base-select name="patient_type" label="Choose Patient Type" v-model="request.event.patient_type" :options="patientTypes"
                    :rules="{ custom_required: true }" placeholder="Patient type" label-required collapse-tags multiple custom-multiple />
              </div>
              <div class="col-12">
                <base-input label="Groups To Remind" name="Groups To Remind">
                  <el-checkbox-group
                    class="w-100 row m-0"
                    v-model="request.event.stages"
                  >
                    <div class="col-sm-3 col-12 p-0 pr-2">
                      <div class="stage_box w-100 pt-3 pb-2 px-3">
                        <el-checkbox label="25">Considering Surgery</el-checkbox>
                      </div>
                    </div>
                    <div class="col-sm-3 col-12 p-0 pr-2">
                      <div class="stage_box w-100 pt-3 pb-2 px-3">
                        <el-checkbox label="50">Pre-Op</el-checkbox>
                      </div>
                    </div>
                    <div class="col-sm-3 col-12 p-0 pr-2">
                      <div class="stage_box w-100 pt-3 pb-2 px-3">
                        <el-checkbox label="75">Post-Op</el-checkbox>
                      </div>
                    </div>
                    <div class="col-sm-3 col-12 p-0 pr-2">
                      <div class="stage_box w-100 pt-3 pb-2 px-3">
                        <el-checkbox label="150">Non-Surgical</el-checkbox>
                      </div>
                    </div>
                  </el-checkbox-group>
                </base-input>
              </div>
              <div class="col-12">
                <el-checkbox v-model="request.event.push_notification">
                  <h3 class="form-control-label">24 Hour Reminder</h3>
                </el-checkbox>
              </div>
            </div>
          </div>
        </div>
        <div class="my-4">
          <base-button native-type="submit" type="bari" class="px-5" size="xl" :disabled="loader">
            {{ submitButton }}
          </base-button>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import moment from "moment";
import {constants} from "@/util/constants";

export default {
  name: "Form",
  props: {
    formType: {
      type: String,
      default: 'Store'
    },
    loader: {
      type: Boolean,
      default: false
    },
    event: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: this.$store.state.toolbarOptions,
      today: moment().format('YYYY-MM-DD'),
      loaders: {
        form: false,
        location: false
      },
      request: {
        event: {...this.$store.getters['EventModule/getEvent']}
      },
      response: {
        locations: [],
      },
      custom5MinuteReminderMessageEdited: false,
      dropdownsOptions: {
        status: constants.common.statusOptions,
        weightLoss: [
          {label: 'All', value: ''},
          {label: 'Surgical', value: '1',},
          {label: 'Non-Surgical', value: '2',}
        ],
        venue: [
          {label: 'All', value: ''},
          {label: 'In-Person', value: '1',},
          {label: 'Virtual', value: '2',}
        ],
        type: [
          {label: 'Fixed Date', value: 1,},
          {label: 'Weekly', value: 2,}
        ],
        locations: [
          {label: 'Other', value: ''},
        ],
      },
      patientTypes: [
        {
          value: 'non_connected',
          label: 'Non connected'
        },
        {
          value: 'connected',
          label: 'Connected'
        }
      ]
    }
  },
  computed: {
    submitButton() {
      return this.formType == 'store' ? 'Add Event' : 'Update'
    },
  },
  mounted() {
    this.getLocations()
  },
  watch: {
    event(newVal) {
      let event = newVal
      event.date = moment(event.date).format("MM/DD/YYYY")
      event.stages = event.stage.length ? event.stage.split(',') : []
      event.patient_type = event.patient_type?.length ? event.patient_type.split(',') : []

      //Casting
      event.push_notification = Boolean(event.push_notification)
      event.five_minute_earlier_reminder = false;
      event.venue_type = event.venue_type || ''
      event.weight_loss_type = event.weight_loss_type || ''

      if (event.location_id == null) {
        event.location_id = ''
      }

      this.request.event = {...event}

      if (event.id) {
        if (event.custom_reminder_message && event.custom_reminder_message.trim() != "") {
          this.custom5MinuteReminderMessageEdited = true
          this.request.event.custom_reminder_message = event.custom_reminder_message
        } else {
          this.custom5MinuteReminderMessageEdited = false
          this.request.event.custom_reminder_message = 'We are starting <Event Name> in 5 minutes.'
        }
      } else {
        this.custom5MinuteReminderMessageEdited = false
        this.request.event.custom_reminder_message = 'We are starting <Event Name> in 5 minutes.'
      }
    },

    'request.event.event_title': function (newVal) {
      if (!this.custom5MinuteReminderMessageEdited) {
        if (newVal == "") {
          this.$set(this.request.event, 'custom_reminder_message', `We are starting <Event Name> in 5 minutes.`)
        } else {
          this.$set(this.request.event, 'custom_reminder_message', `We are starting ${this.request.event.event_title} in 5 minutes.`)
        }
      }
    }
  },
  methods: {
    /**
     * Update Event address on change of location
     */
    updateEventAddress() {
      if (this.request.event.location_id == '') {
        this.request.event.location = ''
      } else {
        const location = this.response.locations.find(location => location.id == this.request.event.location_id)
        this.request.event.location = location ? location.address : ''
      }
    },

    /**
     * Handle date processing
     * @param e
     */
    handleDate(e) {
      if (moment(e.target.value).format('MM/DD/YYYY') == 'Invalid date') {
        this.request.event.date = ''
      }

      if (moment(this.request.event.date) < moment(this.today)) {
        this.request.event.date = moment(this.today).format('MM/DD/YYYY')
      }
    },

    /**
     * On date change context
     * @param ctx
     */
    onContextDate(ctx) {
      if (ctx.selectedYMD != '') {
        this.request.event.date = ctx.selectedFormatted
      }
    },

    /**
     * Get Locations
     */
    getLocations() {
      let vm = this
      vm.$store.dispatch('LocationModule/_getLocations', {})
        .then(response => {
          vm.response.locations = response.data.data.locations
          vm.prepareLocationsData(response.data.data.locations)
        })
        .catch(error => {
          const message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.'
          vm.$notify.error({
            title: 'Event Locations',
            message: message
          })
        })
    },

    /**
     * Prepare Locations Data
     * @param locations
     */
    prepareLocationsData(locations) {
      let vm = this
      vm.dropdownsOptions.locations = []

      locations.forEach(location => {
        vm.dropdownsOptions.locations.push({
          label: location.name,
          value: location.id.toString(),
        })
      })

      vm.dropdownsOptions.locations.push({
        label: 'Other', value: ''
      })
    },

    /**
     * On submit form action
     */
    onSubmit() {
      if (this.formType == 'store') {
        this.$emit('store', {...this.request.event})
      } else if (this.formType == 'update') {
        this.$emit('update', {...this.request.event})
      }
    }
  }
}
</script>

<style scoped>
.input-height-equal >>> .el-input__inner {
  height: calc(1.5em + 1.25rem + 5px) !important;
}
</style>

<style lang="scss">
.stage_box {
  width: fit-content;
  height: fit-content;
  border-radius: 4px;
  border: 1px solid #DEE2E6;
}

.heading-datepicker {
  ::v-deep .date-container {
    border: 0;
  }

  ::v-deep .calendar-button {
    margin-left: 6px;
    border: 1px solid transparent;
  }

  ::v-deep .date-input {
    .el-input__inner {
      border: 1px solid #dee2e6;
      background-color: white;
      color: #32325d;
      font-weight: 500;

      &::placeholder {
        color: #32325d;;
      }
    }
  }
}

.custom-time-picker {
  width: 95% !important;
}
</style>
